/******************
     Contact
*******************/

.custom-form {
    .form-control {
        height: 45px;
        padding: 0.375rem 1.1rem;
        font-size: 14px;
        border-width: 2px;
        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }
    textarea{
        &.form-control{
            height: auto;
        }
    }
}

.form-control{
    &:focus {
        border-color: #9da0a9;
    }
}

.error {
    margin: 8px 0px;
    display: none;
    color: $danger;
}

#ajaxsuccess {
    font-size: 16px;
    width: 100%;
    display: none;
    clear: both;
    margin: 8px 0px;
}

.error_message {
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    border: 2px solid $danger;
    color: $danger;
    border-radius: 5px;
    font-size: 14px;
}

.contact-loader {
    display: none;
}

#success_page {
    text-align: center;
    margin-bottom: 50px;
    h3 {
        color: $success;
        font-size: 22px;
    }
}
