
/******************
    Business Layout
*******************/

/******** Business-home ********/

.home-slider {
    position: relative;
    .carousel-control-next, .carousel-control-prev{
        width: 6%;
    }
    .carousel-item, .carousel{
        height: 100vh;
        width: 100%;
    }
    .carousel-item {
        background-position: center center !important;
        background-size: cover !important;
    }
}

/******** Business-about ********/

.busi-services-box{
    box-shadow: 0 10px 50px rgba(20, 50, 47, 0.03), 0 10px 40px rgba(0, 0, 0, 0.03);
}

.busi-about-content {
    h3{
        max-width: 660px;
        line-height: 1.4;
    }
}

.busi-services-icon {
    i{
        position: absolute;
        width: 64px;
        height: 76px;
        text-align: center;
        line-height: 60px;
        top: 24px;
        right: 48px;
        clip-path: polygon(100% 0, 100% 75%, 50% 100%, 0% 75%, 0 0);
    }
}

/******** Business-portfolio ********/

.busi-container-filter {
    li {
        list-style: none;
        display: inline-block;
        a {
            display: block;
            font-size: 11px;
            color: #6c757d !important;
            background-color: $light;
            border: 1px solid darken($light, 4%);
            padding: 0px 15px;
            margin: 5px 3px;
            text-transform: uppercase;
            letter-spacing: 2px;
            cursor: pointer;
            line-height: 34px;
            border-radius: 3px;
            -webkit-transition: all 0.6s;
        }
    }
}

/******** Business-testi ********/

.busi-testi-box {
    box-shadow: 0 10px 50px rgba(20, 50, 47, 0.03), 0 10px 40px rgba(0, 0, 0, 0.03);
}

.busi-testi-icon {
    i {
        position: absolute;
        right: 30px;
        top: -18px;
        color: darken($light, 5%);
    }
}

/******** Business-team ********/

.busi-team-img {
    img{
        border-radius: 8px;
        max-width: 120px;
    }
}